import Job from 'models/Job';

import Component from './component';

export interface ParentProps {
    job?: Job;
    iconsAlts?: {
        location?: string;
        star?: string;

    }
}

export interface Props extends ParentProps {
    offerBeltTitle?: string;
}

Component.defaultProps = {
    offerBeltTitle: null,
};

export default Component;