import { FunctionComponent } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { Routes } from 'types/routes';

import { imageResizer } from 'utils/image';
import { shorten, withVariables } from 'utils/string';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutJobsSmallJobBoxMobile: FunctionComponent<Props> = ({ job, iconsAlts }) => {
    const router = useRouter();
    const { publicRuntimeConfig } = getConfig();

    return (
        <StyledComponent className="public-layout-jobs-small-job-box-mobile">
            <a
                className='offer-link'
                href={(withVariables(Routes?.PublicJob, { slug: job?.slug }, { jobCitySlug: job?.primaryCity?.id }))}
            >
                <div className="image-container">
                    <img
                        className="offer-image"
                        src={imageResizer(job.companyLogo?.imageUrl, { width: 200 }) || '/images/jobs/image-placeholder.svg'}
                        loading="lazy"
                        alt={job?.companyName}
                        width='112'
                        height='112'
                    />
                </div>
                <div className="offer-content">
                    <div
                        className="offer-name"
                        dangerouslySetInnerHTML={{ __html: shorten(job?.name, 30, true) }}
                    />
                    <div
                        className="company-name"
                        dangerouslySetInnerHTML={{ __html: job?.companyName }}
                    />
                    {job?.companyFullAddress && (
                        <div className="content-description">
                            <div className="location">
                                <img
                                    className="icon"
                                    src="/images/icons/bullet3.svg"
                                    loading="lazy"
                                    width="20"
                                    height="auto"
                                    alt={iconsAlts?.location}
                                />
                                <div
                                    className="title"
                                    dangerouslySetInnerHTML={{ __html: job?.primaryCity?.companyFullAddress }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {job.topOffer && (
                    <div
                        className="offer-belt"
                        style={{
                            backgroundImage: `url(${publicRuntimeConfig.APP_URL}/images/icons/underline.svg)`,
                        }}
                    >
                        <img
                            className="icon"
                            src="/images/icons/star.svg"
                            loading="lazy"
                            alt={iconsAlts?.star}
                            width='14'
                            height='14'
                        />
                        <div
                            className="title"
                            dangerouslySetInnerHTML={{ __html: 'Top oferta' }}
                        />
                    </div>
                )}
            </a>
        </StyledComponent>
    );
};

export default PublicLayoutJobsSmallJobBoxMobile;