import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    border: .1em solid #000000;
    background-color: #FFFFFF;
    min-height: 26em;
    .offer-link {
        text-decoration: none;
        color: #000000;
    }
    .image-container {
        position: relative;
        width: 100%;
        height: 10em;

        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: .1em solid #000000;
        padding: 1em;
        .offer-image {
           max-width: 15vw;
           object-fit: scale-down;
        }
    }
    .offer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1.5em;

        .offer-name {
            width: 100%;
            font-size: 1.7em;
            font-weight: 700;
            color: #000000;
        }

        .company-name {
            font-size: 1.3em;
            color: #000000;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .content-description {
            .location {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon {
                   width: 1em;
                   margin-right: 1em;
                }

                .title {
                    font-size: 1.3em;
                    color: #000000;
                    &::first-letter {
                        text-transform: uppercase;
                    }

                    .highlighted {
                        font-weight: 700;
                    }
                }
            }
        }

        > * {
            padding-top: 1em;

            &:first-of-type {
                padding-top: 0;
            }
        }
    }

    .offer-belt {
        position: absolute;
        width: 100%;
        height: 3em;
        top: -5%;
        left: 50%;
        transform: translate(-50%, -5%);
        background-repeat: no-repeat;
        background-position: center;
        font-weight: 700;

        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            margin-right: .5em;
        }
    }

    &:hover {
        cursor: pointer;

        .offer-header {
            .offer-name {
                text-decoration: underline;
            }
        }
    }
    @media (max-width:${variables.mobileL}) {
        .image-container {
            .offer-image {
           max-width: 30vw;
        }
        }

    }
`;