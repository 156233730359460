import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	position: relative;
	width: 100%;
	border: 0.1em solid #000000;
	background-color: #ffffff;
	max-width: 18em;
	min-height: 19.2em;
	.offer-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 0.1em solid #000000;

		.layout-tooltip {
			max-width: 70%;
			overflow: hidden;
			.react-tooltip {
				padding: 1em;
			}

			.tooltip-name {
				max-width: 11.4em;
				font-size: 1em;
				overflow-wrap: break-word;
			}

			.tooltip-container {
				.offer-name {
					width: 100%;
					font-size: 1em;
					font-weight: 700;
					color: #000000;
					padding: 0 1.5em;
					overflow-wrap: break-word;
				}
			}
		}

		.offer-name {
			width: 70%;
			font-size: 1em;
			font-weight: 700;
			color: #000000;
			padding: 0 1.5em;
		}

		.image-container {
			min-width: 30%;
			width: 100%;
			max-width: 7em;
			border-left: 0.1em solid #000000;
			padding: 1em;

			.offer-image {
				width: 100%;
				height: 5em;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
	}
	.offer-link {
		text-decoration: none;
	}
	.offer-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 1.5em 1.5em 2em;

		.company-name {
			font-size: 1em;
			color: #000000;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.content-description {
			margin-top: 1em;

			.description {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.icon {
					max-width: 1em;
					margin-right: 1em;
				}

				.title {
					font-size: 1em;
					color: #000000;
					&::first-letter {
						text-transform: capitalize;
					}

					&.highlighted {
						font-weight: 700;
					}
				}
			}

			> * {
				margin-top: 0.7em;

				&:first-of-type {
					margin-top: 0;
				}
			}
		}
	}

	.offer-belt {
		position: absolute;
		width: 100%;
		top: -5%;
		left: 50%;
		transform: translate(-50%, -5%);
		background-repeat: no-repeat;
		background-position: center;
		font-weight: 700;
		color: #000000;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			margin-right: 0.5em;
		}
	}

	&:hover {
		cursor: pointer;

		.offer-header {
			.offer-name {
				text-decoration: underline;
			}
		}
	}

	@media all and (max-width: ${variables.desktopS}) {
		.offer-header {
			.layout-tooltip {
				.tooltip-name {
					max-width: 9em;
					font-size: 0.9em;
				}
			}
		}
	}

	@media all and (max-width: ${variables.tabletL}) {
		max-width: 100%;
	}
`;
